<template>
  <div>
    <!-- picEDD7C92A2DABB8AB4B7735523C831F0B.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/pirate-captain-sets-sail-for-mln.jpg">
    <p>
      A new character from LEGO Universe, Captain Jack Knife, will soon drop anchor on My LEGO Network!
    </p>
    <p>
      Captain Jack Knife lives for the moment while leading his band of hearties (that’s pirate-speak for “friends”) the Black Seas Barracuda!
    </p>
    <p>
      The Barracuda pirates once proudly plundered the waters of LEGO Universe, at least until the captains’ blustery bravado got their ship stuck in a shady part of town!
    </p>
    <p>
      So far, the Barracuda have missed every shot they’ve taken at getting their boat back out to sea. Part of the reason for the misfires is that Captain Jack Knife’s crew doesn’t pay him much mind. After all, he’s known to call them things like “lazy scalawags” while he’s lying in a hammock drinking root beer!
    </p>
    <p>
      The Barracuda pirates’ mountain of booty may not tower as high as it once did, but the captain still has a treasure trove of lovely trinkets for trading!
    </p>
    <p>
      Captain Jack Knife says, “Yar me young hearties, ye can come an’ see me soon on My LEGO Network! Har har! Together we shall share root beer and talk o’ tradin’ fer a few o’ me beauties! Yar ‘twill be a bigger blast than a bloomin’ boatload o’ cannons! Arrrr!!”
    </p>
  </div>
</template>
